<template>
  <div>
    <div class="container-inputs">
      <div class="container-inputs__inputs ml-1">
        <span
          :class="[
            { 'container-inputs__titles': filters.origin === '' },
            { 'container-inputs__titles-active': filters.origin !== '' },
          ]"
        >
          <i class="el-icon-location mr-1"></i>
          Origin
        </span>
        <div class="input-container mt-1">
          <input type="text" class="input-information" v-model="filters.origin" />
        </div>
      </div>
      <div class="container-inputs__inputs ml-1">
        <span
          :class="[
            { 'container-inputs__titles': filters.destination === '' },
            { 'container-inputs__titles-active': filters.destination !== '' },
          ]"
        >
          <i class="el-icon-add-location mr-1"></i>
          Destination
        </span>
        <div class="input-container mt-1">
          <input type="text" class="input-information" v-model="filters.destination" />
        </div>
      </div>
      <div class="container-inputs__inputs ml-1">
        <span
          :class="[
            { 'container-inputs__titles': filters.carrier === '' },
            { 'container-inputs__titles-active': filters.carrier !== '' },
          ]"
        >
          <b-icon icon="truck" font-scale="1" class="mr-1"></b-icon>
          Carrier
        </span>
        <div class="input-container mt-1">
          <input type="text" class="input-information" v-model="filters.carrier" />
        </div>
      </div>
      <div class="container-inputs__inputs ml-1">
        <span
          :class="[
            { 'container-inputs__titles': filters.customer === '' },
            { 'container-inputs__titles-active': filters.customer !== '' },
          ]"
        >
          <b-icon icon="person" font-scale="1" class="mr-1"></b-icon>
          Customer
        </span>
        <div class="input-container mt-1">
          <input type="text" class="input-information" v-model="filters.customer" />
        </div>
      </div>
      <div class="mt-1 selector-list">
        <span
          :class="[
            { 'container-inputs__titles': filters.modes.length <= 0 },
            { 'container-inputs__titles-active': filters.modes.length >= 1 },
          ]"
        >
          <i class="ion ion-pencil-outline mr-1"></i>
          Modes
        </span>
        <el-select
          v-model="filters.modes"
          multiple
          collapse-tags
          collapse-tags-tooltip
          placeholder="Select the modes to filter"
        >
          <el-option
            v-for="item in optionModesPicklist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import modesPicklist from "../../utils/MyLoadsModesPicklist";
/* eslint-disable global-require */
export default {
  name: "filtersSearchs",
  components: {},
  props: {
    data: Object,
    reset: Boolean,
  },
  data() {
    return {
      filters: {
        origin: "",
        destination: "",
        carrier: "",
        customer: "",
        modes: []
      },
      filterProperties: ["origin", "destination", "carrier", "customer", "modes"],
      optionModesPicklist: [],
    };
  },
  watch: {
    reset() {
      if (this.reset) {
        this.resetFilters();
      }
    },
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("changeFilersSearchs", this.filters);
      },
    },
  },
  created() {
    this.optionModesPicklist = modesPicklist;
  },
  methods: {
    infoFilter(data) {
      if (data !== null) {
        this.filterProperties.forEach((property) => {
          if (data[property] !== "") {
            this.filters[property] = data[property];
          }
        });
      }
    },
    resetFilters() {
      this.filterProperties.forEach((property) => {
        this.filters[property] = "";
      });
    },
  },
  mounted() {
    this.infoFilter(this.data);
  },
};
</script>

<style lang="scss" scoped>
.container-inputs {
  &__inputs {
    flex-direction: row;
  }
  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__titles-active {
    font-size: 11px;
    font-family: $font-family-portal;
    color: $color-border-container;
  }
}
// search input
.input-container {
  @include input-information;
  border: 1px solid #fff;
  color: $color-primary-company;
  padding: 7px 0px 5px 5px;
  width: 95%;
  height: 25px;
  box-shadow: none;
  border-radius: 5px;
  margin: 0px;
  font-family: $font-family-portal;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  font-size: 11px;
  color: #606266;
}

.selector-list {
  display: flex;
  flex-direction: column;
}

.selector-list ::v-deep .el-input__inner {
  width: 100%;
  height: 30px;
}

::v-deep .el-select {
  width: 100%;
  padding-left: 6px;
  padding-right: 13px;
  &__tags {
    padding-right: 42px;
    flex-wrap: nowrap;
  }
}
</style>
