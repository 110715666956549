<template>
  <div class="my-loads">
    <div class="my-loads-content" v-if="screenWidth >= 800">
      <MyLoadsVersion2
        :rowLoads="data"
        :loadingItems="loadingItems"
        :firstItemsLoaded="firstItemsLoaded"
        :perPage="perPage"
        :limit="limit"
        :showCost="showCost"
        :filters="savedFilters"
        :filtersFromDashboard="filtersFromDashboard"
        ref="myLoadsTable"
        @loadMoreItems="loadMoreItemsScroll"
        @loadMoreItemsByFilters="loadMoreItemsByFilters"
        @changePerPage="changePerPage"
      ></MyLoadsVersion2>
    </div>
    <div div class="my-loads-mobile" v-else>
      <MyLoadsResponsive
        :data="data"
        :loadingItems="loadingItems"
        :firstItemsLoaded="firstItemsLoaded"
        :perPage="perPage"
        :limit="limit"
        :filters="savedFilters"
        :filtersFromDashboard="filtersFromDashboard"
        @loadMoreItems="loadMoreItems"
        @changePerPage="changePerPage"
        @changeSortBy="changeSortBy"
        @changeFilterBy="changeFilterBy"
        :showCost="showCost"
      />
    </div>
  </div>
</template>

<script>
import myLoadsFilterSchema from "@/utils/MyLoadsFiltersSchema";
import { mapGetters } from "vuex";
import MyLoadsResponsive from "./MyLoadsResponsive.vue";
import onReSize from "../../Extend/onResize";
import MyLoadsVersion2 from "./MyLoadsVersion2.vue";

export default {
  name: "LoadListsContainer",
  components: {
    MyLoadsResponsive,
    MyLoadsVersion2,
  },
  mixins: [onReSize],
  data() {
    return {
      data: [],
      skip: 0,
      limit: 35,
      perPage: 35,
      loadingItems: false,
      firstItemsLoaded: false,
      thereIsSearchText: false,
      sortByString: "",
      filterList: null,
      showCost: false,
      ObjectFilterSelected: {},
      objectFilters: {},
      savedFilters: {},
      filtersFromDashboard: false,
    };
  },
  async created() {
    this.objectFilters = { ...myLoadsFilterSchema };
    const { filters } = this.$route.params;
    this.showCost = this.$store.getters["login/getTokenInfo"].show_cost;
    const infoLocalStorage = JSON.parse(localStorage.getItem("my-load-table"));
    this.saveSelectedFilters(filters, infoLocalStorage);
    if (!this.searchTextVuex) {
      if (
        infoLocalStorage !== undefined &&
        infoLocalStorage !== null &&
        infoLocalStorage.filters !== undefined &&
        infoLocalStorage.filters !== null
      ) {
        await this.loadMoreItemsWhitFiltersAvances(this.savedFilters);
      } else {
        await this.loadMoreItemsWhitFiltersAvances(this.savedFilters);
      }
    } else {
      this.thereIsSearchText = true;
      this.addSearchParams();
    }
  },
  computed: {
    ...mapGetters({
      myLoadsSectionFlag: "getMyLoadsSectionFlag",
      searchTextVuex: "load/getSearchText",
    }),
  },
  watch: {
    myLoadsSectionFlag() {
      this.data = [];
      this.firstItemsLoaded = false;
      this.skip = 0;
      this.loadMoreItemsWhitFiltersAvances(this.objectFilters);
    },
    searchTextVuex() {
      this.firstItemsLoaded = false;
      if (this.searchTextVuex !== "") {
        this.thereIsSearchText = true;
        this.addSearchParams();
      } else {
        this.data = [];
        this.thereIsSearchText = false;
        this.loadMoreItemsWhitFiltersAvances(this.ObjectFilterSelected);
      }
    },
    loadingItems(value) {
      const myLoadsTableRef = this.$refs.myLoadsTable;
      if (myLoadsTableRef) {
        myLoadsTableRef.scrollableTableContainer.style.overflow = value ? "hidden" : "auto";
      }
    },
  },
  methods: {
    addSearchParams() {
      this.data = [];
      this.skip = 0;
      this.loadMoreItems();
    },
    changeResponsive() {
      this.data = [];
    },
    loadMoreItemsScroll(data) {
      if (data !== null) {
        this.loadMoreItemsWhitFiltersAvances(data);
      } else {
        this.loadMoreItemsWhitFiltersAvances(this.objectFilters);
      }
    },
    loadMoreItemsByFilters(data) {
      this.data = [];
      this.skip = 0;
      this.firstItemsLoaded = false;
      this.loadMoreItemsWhitFiltersAvances(data);
    },
    changePerPage(number) {
      this.perPage = number;
      this.limit = number;
    },
    async loadMoreItems() {
      if (this.$options.filters.checkPermission("get:loads")) {
        this.loadingItems = true;
        this.perPage = 35;
        if (this.skip < 2000) {
          if (this.firstItemsLoaded) {
            this.skip += this.perPage;
            if (this.skip > 2000) {
              this.skip = 2000;
            }
          }
          const data = {
            params: {
              limit: this.limit,
              skip: this.skip,
              ...(this.thereIsSearchText && { search: this.searchTextVuex }),
            },
          };
          const response = await this.$store.dispatch("load/getAllLoads", data);
          this.data = this.data.concat(response);
          if (!this.firstItemsLoaded) {
            this.firstItemsLoaded = true;
          }
        }
        this.thereIsSearchText = false;
        this.loadingItems = false;
      }
    },
    async loadMoreItemsWhitFiltersAvances(data) {
      if (this.$options.filters.checkPermission("get:loads")) {
        this.ObjectFilterSelected = data;
        this.loadingItems = true;
        this.perPage = 35;
        if (this.skip < 2000) {
          if (this.firstItemsLoaded) {
            this.skip += this.perPage;
            if (this.skip > 2000) {
              this.skip = 2000;
            }
          }
          const dataFilter = {
            limit: this.limit,
            skip: this.skip,
            ...(data.origin && {
              origin: data.origin,
            }),
            ...(data.destination && {
              destination: data.destination,
            }),
            ...(data.statuses.length !== 0 && {
              statuses: data.statuses,
            }),
            ...(data.modes.length !== 0 && {
              modes: data.modes,
            }),
            ...(data.accessorial_id && {
              accessorial_id: data.accessorial_id,
            }),
            ...(data.carrier && {
              carrier: data.carrier,
            }),
            ...(data.customer && {
              customer_name: data.customer,
            }),
            ...(data.created_date_gt && {
              created_date_gt: data.created_date_gt,
            }),
            ...(data.created_date_lt && {
              created_date_lt: data.created_date_lt,
            }),
            ...(data.expected_delivery_date_gt && {
              expected_delivery_date_gt: data.expected_delivery_date_gt,
            }),
            ...(data.expected_delivery_date_lt && {
              expected_delivery_date_lt: data.expected_delivery_date_lt,
            }),
            ...(data.expected_ship_date_gt && {
              expected_ship_date_gt: data.expected_ship_date_gt,
            }),
            ...(data.expected_ship_date_lt && {
              expected_ship_date_lt: data.expected_ship_date_lt,
            }),
            ...(data.actual_delivery_date_gt && {
              actual_delivery_date_gt: data.actual_delivery_date_gt,
            }),
            ...(data.actual_delivery_date_lt && {
              actual_delivery_date_lt: data.actual_delivery_date_lt,
            }),
            ...(data.actual_pickup_date_gt && {
              actual_pickup_date_gt: data.actual_pickup_date_gt,
            }),
            ...(data.actual_pickup_date_lt && {
              actual_pickup_date_lt: data.actual_pickup_date_lt,
            }),
            ...(data.included_accessorials && {
              included_accessorials: data.included_accessorials,
            }),
            ...(data.order_by && {
              order_by: data.order_by,
            }),
            ...(data.state_search && {
              state_search: data.state_search,
            }),
          };
          const response = await this.$store.dispatch("load/getLoadsAdvanced", dataFilter);
          this.data = this.data.concat(response);
          if (!this.firstItemsLoaded) {
            this.firstItemsLoaded = true;
          }
        }
        this.thereIsSearchText = false;
        this.loadingItems = false;
      }
    },
    changeSortBy(sortInfo) {
      if (sortInfo.type !== "") {
        switch (sortInfo.name) {
          case "loadNumberSort":
            this.sortByString = `name ${sortInfo.type}`;
            break;
          case "originSort":
            this.sortByString = `origin ${sortInfo.type}`;
            break;
          case "destinationSort":
            this.sortByString = `destination ${sortInfo.type}`;
            break;

          default:
            break;
        }
      } else {
        this.sortByString = "";
      }
      this.data = [];
      this.skip = 0;
      this.firstItemsLoaded = false;
      const filters = JSON.parse(JSON.stringify(this.objectFilters));
      filters.order_by = this.sortByString;
      filters.statuses = this.filterList;
      this.loadMoreItemsWhitFiltersAvances(filters);
    },
    changeFilterBy(filterList) {
      this.data = [];
      this.skip = 0;
      this.firstItemsLoaded = false;
      const filters = JSON.parse(JSON.stringify(this.objectFilters));
      filters.order_by = this.sortByString;
      filters.statuses = filterList;
      this.filterList = filterList;
      this.loadMoreItemsWhitFiltersAvances(filters);
    },
    saveSelectedFilters(filters, localStorageFilters) {
      if (filters) {
        this.savedFilters = filters;
        this.filtersFromDashboard = true;
      } else if (
        localStorageFilters !== undefined &&
        localStorageFilters !== null &&
        localStorageFilters.filters !== undefined &&
        localStorageFilters.filters !== null
      ) {
        this.savedFilters = localStorageFilters.filters;
        this.filtersFromDashboard = false;
      } else {
        this.savedFilters = this.objectFilters;
        this.filtersFromDashboard = false;
      }
      this.objectFilters = this.savedFilters;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-loads-content {
  margin: 16px;
}
</style>
