var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-inputs"},[_c('div',{staticClass:"container-inputs__inputs ml-1"},[_c('span',{class:[
          { 'container-inputs__titles': _vm.filters.origin === '' },
          { 'container-inputs__titles-active': _vm.filters.origin !== '' },
        ]},[_c('i',{staticClass:"el-icon-location mr-1"}),_vm._v(" Origin ")]),_c('div',{staticClass:"input-container mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.origin),expression:"filters.origin"}],staticClass:"input-information",attrs:{"type":"text"},domProps:{"value":(_vm.filters.origin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "origin", $event.target.value)}}})])]),_c('div',{staticClass:"container-inputs__inputs ml-1"},[_c('span',{class:[
          { 'container-inputs__titles': _vm.filters.destination === '' },
          { 'container-inputs__titles-active': _vm.filters.destination !== '' },
        ]},[_c('i',{staticClass:"el-icon-add-location mr-1"}),_vm._v(" Destination ")]),_c('div',{staticClass:"input-container mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.destination),expression:"filters.destination"}],staticClass:"input-information",attrs:{"type":"text"},domProps:{"value":(_vm.filters.destination)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "destination", $event.target.value)}}})])]),_c('div',{staticClass:"container-inputs__inputs ml-1"},[_c('span',{class:[
          { 'container-inputs__titles': _vm.filters.carrier === '' },
          { 'container-inputs__titles-active': _vm.filters.carrier !== '' },
        ]},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"truck","font-scale":"1"}}),_vm._v(" Carrier ")],1),_c('div',{staticClass:"input-container mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.carrier),expression:"filters.carrier"}],staticClass:"input-information",attrs:{"type":"text"},domProps:{"value":(_vm.filters.carrier)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "carrier", $event.target.value)}}})])]),_c('div',{staticClass:"container-inputs__inputs ml-1"},[_c('span',{class:[
          { 'container-inputs__titles': _vm.filters.customer === '' },
          { 'container-inputs__titles-active': _vm.filters.customer !== '' },
        ]},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"person","font-scale":"1"}}),_vm._v(" Customer ")],1),_c('div',{staticClass:"input-container mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.customer),expression:"filters.customer"}],staticClass:"input-information",attrs:{"type":"text"},domProps:{"value":(_vm.filters.customer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "customer", $event.target.value)}}})])]),_c('div',{staticClass:"mt-1 selector-list"},[_c('span',{class:[
          { 'container-inputs__titles': _vm.filters.modes.length <= 0 },
          { 'container-inputs__titles-active': _vm.filters.modes.length >= 1 },
        ]},[_c('i',{staticClass:"ion ion-pencil-outline mr-1"}),_vm._v(" Modes ")]),_c('el-select',{attrs:{"multiple":"","collapse-tags":"","collapse-tags-tooltip":"","placeholder":"Select the modes to filter"},model:{value:(_vm.filters.modes),callback:function ($$v) {_vm.$set(_vm.filters, "modes", $$v)},expression:"filters.modes"}},_vm._l((_vm.optionModesPicklist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }