<template>
  <div class="my-loads">
    <div class="my-loads__tools">
      <MyLoadsFiltersAdvance
        :active="activeFilters"
        :data="filtersSelectedStorage"
        @changeStatusFilters="changeStatusFilters"
        @selectedFilters="applyFilters"
        @resetFilters="resetFilters"
      ></MyLoadsFiltersAdvance>
      <AddColumnsGrid
        :active="activeColumns"
        :data="columnsSelectedStorage"
        :type="'myLoad'"
        @changeStatusColumns="changeStatusColumns"
        @addColumns="applyColumns"
      ></AddColumnsGrid>
      <div class="export-button" @click="exportData()">
        <i v-b-popover.hover.top="'Export'">
          <i v-if="!loadingExport" class="el-icon-download"></i>
          <b-spinner v-if="loadingExport" variant="primary" font-scale="1"></b-spinner>
        </i>
      </div>
    </div>
    <div class="my-loads__loading">
      <div class="loading loading__height" v-if="loadingItems || loadingItemsCopy">
        <b-icon icon="truck" animation="cylon" font-scale="3"></b-icon>
        <FadeAnimationTextArray :textArray="loadingTextArray" />
      </div>
    </div>
    <div class="my-loads__table mt-2" @click="inactiveTools()">
      <ag-grid-vue
        style="width: 100%"
        ref="agGridTable"
        class="ag-theme-material ag-grid-table"
        suppressHorizontalScroll="true"
        domLayout="normal"
        autoHeight="true"
        rowSelection="single"
        :columnDefs="columnDefs"
        :components="gridComponents"
        :defaultColDef="defaultColDef"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="false"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="false"
        :rowModelType="rowModelType"
        :cacheBlockSize="cacheBlockSize"
        :cacheOverflowSize="cacheOverflowSize"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :infiniteInitialRowCount="infiniteInitialRowCount"
        :maxBlocksInCache="maxBlocksInCache"
        :gridOptions="gridOptions"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
        @body-scroll="onBodyScrollEndEvent"
      >
      </ag-grid-vue>
    </div>
    <div class="my-loads__not-found" v-if="notFound">
      <i class="ion ion-file-tray-outline my-loads__not-found__icon"></i>
      <p>No loads were found.</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import XLSX from "xlsx/xlsx";
import { mapGetters } from "vuex";
import "@/assets/scss/components/agGridStyleOverride.scss";
import onResize from "@/Extend/onResize";
import LoadStatus from "@/components/CellRendered/LoadStatus.vue";
import LoadDateExpectedEpd from "@/components/CellRendered/LoadDateExpectedEpd.vue";
import LoadDateExpectedEdd from "@/components/CellRendered/LoadDateExpectedEdd.vue";
import LoadDates from "@/components/CellRendered/LoadDates.vue";
import LoadRevenueCost from "@/components/CellRendered/LoadRevenueCost.vue";
import LoadAccesorials from "@/components/CellRendered/LoadAccesorials.vue";
import LoadProNumber from "@/components/CellRendered/LoadProNumber.vue";
import LoadSHP from "@/components/CellRendered/LoadSHP.vue";
import CustomerCell from "@/components/CellRendered/Customer.vue";
import MyLoadsFiltersAdvance from "@/components/MyLoadsFiltersAdvance.vue";
import AddColumnsGrid from "@/components/AddColumnsGrid.vue";
import LoadStatusType from "../../Extend/LoadStatusType";
import FadeAnimationTextArray from "../../components/FadeAnimationTextArray.vue";
import LoadingTextArray from "../../utils/LoadingTextArray";

export default {
  name: "my-load-v2",
  mixins: [LoadStatusType, onResize],
  components: {
    AgGridVue,
    LoadStatus,
    LoadDateExpectedEpd,
    LoadDateExpectedEdd,
    LoadDates,
    LoadRevenueCost,
    FadeAnimationTextArray,
    MyLoadsFiltersAdvance,
    LoadAccesorials,
    LoadSHP,
    LoadProNumber,
    AddColumnsGrid,
    CustomerCell,
  },
  props: {
    rowLoads: {
      type: Array,
    },
    limit: Number,
    perPage: Number,
    firstItemsLoaded: Boolean,
    loadingItems: Boolean,
    filters: Object,
    filtersFromDashboard: Boolean,
  },
  data() {
    return {
      columnsSelectedStorage: "",
      filtersSelectedStorage: null,
      searchQuery: "",
      loadingItemsCopy: false,
      loadingTextArray: LoadingTextArray.myLoads,
      arrayFilteredRows: [],
      activeFilters: false,
      activeColumns: false,
      dataFilters: null,
      scrollNumber: 0,
      loadingData: false,
      notFound: false,
      pageSelector: 0,
      gridApi: null,
      activeExport: false,
      maxLimit: 119,
      fileName: "My loads",
      filetype: "xlsx",
      sheetName: "sheetname",
      loadingExport: false,
      gridOptions: {
        rowModelType: "infinite",
        deltaRowDataMode: true,
        rowHeight: 36,
        suppressHorizontalScroll: true,
        components: {
          LoadStatus,
          LoadDateExpectedEpd,
          LoadDateExpectedEdd,
          LoadDates,
          LoadRevenueCost,
          LoadAccesorials,
          LoadSHP,
          LoadProNumber,
        },
        suppressRowClickSelection: true,
        onCellClicked: (e) => {
          if (e.column.colId !== "name" && e.column.colId !== "pro_number") {
            // cell is from non-select column
            e.node.setSelected(true);
          }
        },
        onSortChanged: this.onSortChanged,
      },
      defaultColDef: {
        sortable: true,
        resizable: false,
        suppressMenu: true,
      },
      gridComponents: {
        LoadStatus,
        LoadDateExpectedEpd,
        LoadDateExpectedEdd,
        LoadDates,
        LoadRevenueCost,
        LoadAccesorials,
        LoadSHP,
        LoadProNumber,
      },
      sort: {
        name: "name",
        load_status: "load_status",
        origin: "origin",
        destination: "destination",
        revenue_cost: "revenue_cost",
        pro_number: "pro_number",
        "carrier.name": "carrier_name",
      },
      columnsExport: [],
      nameExport: {
        label: "LOAD NUMBER",
        field: "name",
      },
      loadStatusExport: {
        label: "LOAD STATUS",
        field: "load_status",
        dataFormat: this.statusFormat,
      },
      originExport: {
        label: "ORIGIN",
        field: "origin",
      },
      destinationExport: {
        label: "DESTINATION",
        field: "destination",
      },
      proNumberExport: {
        label: "PRO NUMBER",
        field: "pro_number",
      },
      carrierExport: {
        label: "CARRIER",
        field: "carrier",
        dataFormat: this.carrierFormat,
      },
      accessorialsExport: {
        label: "ACCESSORIALS",
        field: "included_accessorials",
      },
      createdDateExport: {
        label: "CREATED DATE",
        field: "created_date",
        dataFormat: this.priceFormat,
      },
      shipDateDateExport: {
        label: "PICKUP DATE",
        field: "expected_ship_date",
      },
      revenueCostExport: {
        label: "PRICE",
        field: "revenue_cost",
      },
      dateAPDExport: {
        label: "ACTUAL PICKUP DATE",
        field: "actual_pickup_date",
      },
      dateEDDExport: {
        label: "ESTIMATED DELIVERY DATE",
        field: "expected_delivery_date",
      },
      dateADDExport: {
        label: "ACTUAL DELIVERY DATE",
        field: "actual_delivery_date",
      },
      customerExport: {
        label: "CUSTOMER",
        field: "customer",
      },
      columnDefs: [],
      columnDefsSeleted: [],
      name: {
        headerName: "LOAD NUMBER",
        label: "LOAD NUMBER",
        field: "name",
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        autoHeight: true,
        editable: false,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadSHP",
        sortable: true,
        suppressSizeToFit: false,
      },
      loadStatus: {
        headerName: "LOAD STATUS",
        label: "LOAD STATUS",
        field: "load_status",
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadStatus",
        editable: false,
        suppressSizeToFit: false,
        sortable: true,
      },
      origin: {
        headerName: "ORIGIN",
        label: "ORIGIN",
        field: "origin",
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        editable: false,
        suppressSizeToFit: false,
        sortable: true,
      },
      destination: {
        headerName: "DESTINATION",
        label: "DESTINATION",
        field: "destination",
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        editable: false,
        cellClass: "cell-wrap-text",
        suppressSizeToFit: false,
        sortable: true,
      },
      proNumber: {
        headerName: "PRO NUMBER",
        label: "PRO NUMBER",
        field: "pro_number",
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        editable: false,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadProNumber",
        suppressSizeToFit: false,
        sortable: true,
      },
      carrier: {
        headerName: "CARRIER",
        label: "CARRIER",
        field: "carrier.name",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        suppressSizeToFit: false,
        sortable: true,
      },
      accessorials: {
        headerName: "ACCESSORIALS",
        label: "ACCESSORIALS",
        field: "included_accessorials",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadAccesorials",
        suppressSizeToFit: false,
        sortable: false,
      },
      createdDate: {
        headerName: "CREATED DATE",
        label: "CREATED DATE",
        field: "created_date",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        suppressSizeToFit: false,
        cellRendererFramework: "LoadDates",
        sortable: false,
      },
      shipDate: {
        headerName: "ESTIMATED PICKUP DATE",
        label: "PICKUP DATE",
        field: "expected_ship_date",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadDateExpectedEpd",
        suppressSizeToFit: false,
        sortable: false,
      },
      revenueCost: {
        headerName: "PRICE",
        label: "PRICE",
        field: "revenue_cost",
        editable: false,
        filter: true,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadRevenueCost",
        suppressSizeToFit: false,
        sortable: true,
      },
      dateAPD: {
        headerName: "ACTUAL PICKUP DATE",
        field: "actual_pickup_date",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadDates",
        suppressSizeToFit: false,
        sortable: false,
      },
      dateEDD: {
        headerName: "ESTIMATED DELIVERY DATE",
        field: "expected_delivery_date",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        suppressSizeToFit: false,
        sortable: false,
        cellRendererFramework: "LoadDateExpectedEdd",
      },
      dateADD: {
        headerName: "ACTUAL DELIVERY DATE ",
        field: "actual_delivery_date",
        filter: true,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "LoadDates",
        suppressSizeToFit: false,
        sortable: false,
      },
      customer: {
        headerName: "CUSTOMER",
        field: "customer",
        filter: false,
        editable: false,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        autoHeight: true,
        cellClass: "cell-wrap-text",
        cellRendererFramework: "CustomerCell",
        suppressSizeToFit: false,
        sortable: false,
      },
      arrayMyLoads: [],
      columnsSelected: "",
      objectFilters: {
        shp: "",
        origin: "",
        destination: "",
        carrier: "",
        created_date_gt: "",
        created_date_lt: "",
        actual_pickup_date_gt: "",
        actual_pickup_date_lt: "",
        actual_delivery_date_gt: "",
        actual_delivery_date_lt: "",
        expected_ship_date_gt: "",
        expected_ship_date_lt: "",
        expected_delivery_date_gt: "",
        expected_delivery_date_lt: "",
        included_accessorials: "",
        limit: "",
        skip: "",
        order_by: "",
        statuses: [],
        scrollableTableContainer: null,
      },
    };
  },
  created() {
    this.loadingData = true;
    this.pageSelector = this.perPage;
    this.rowBuffer = 0;
    this.rowModelType = "infinite";
    this.cacheBlockSize = 25;
    this.cacheOverflowSize = 2;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1000;
    this.maxBlocksInCache = 10;
    this.filtersSelectedStorage = this.filters;
    if (this.rowLoads.length === 0 && !this.loadingItems) {
      this.notFound = true;
    } else {
      this.notFound = false;
    }
    this.loadingData = false;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.filtersAndColumns();
    this.$nextTick(() => {
      this.scrollableTableContainer = document.querySelector(".ag-body-viewport");
    });
  },
  computed: {
    paginationPageSize() {
      return this.gridApi ? this.gridApi.paginationGetPageSize() : 25;
    },
    ...mapGetters({ isCollapseSidebar: "getCollapseSidebar" }),
  },
  watch: {
    rowLoads() {
      if (this.rowLoads.length !== 0) {
        this.arrayMyLoads = JSON.parse(JSON.stringify(this.rowLoads));
        this.updateData(this.arrayMyLoads, this.gridOptions);
        this.notFound = false;
      } else {
        this.updateData([], this.gridOptions);
        this.notFound = true;
      }
    },
    screenWidth() {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setRowData(this.arrayMyLoads);
    },
    columnsSelected() {
      this.gridApi.sizeColumnsToFit();
    },
    isCollapseSidebar() {
      this.gridApi.sizeColumnsToFit();
    },
  },
  methods: {
    priceFormat(value) {
      return this.$options.filters.dateFormat(value);
    },
    statusFormat(value) {
      return this.setLoadStatusColor(value).label;
    },
    carrierFormat(value) {
      let carrierName = "";
      if (value != null) {
        carrierName = value.name;
      }
      return carrierName;
    },
    clearSortby() {
      const sortModel = this.gridOptions.api.getSortModel(); // Obtener información de ordenamiento
      if (sortModel.length > 0) {
        const sortedColumn = sortModel[0]; // Obtener la primera columna ordenada
        this.gridOptions.api.setSortModel([
          {
            colId: sortedColumn.colId,
            sort: null,
          },
        ]);
      }
    },
    onSortChanged() {
      const sortModel = this.gridOptions.api.getSortModel(); // Obtener información de ordenamiento
      if (sortModel.length > 0) {
        const sortedColumn = sortModel[0]; // Obtener la primera columna ordenada
        if (this.sort[sortedColumn.colId]) {
          if (this.dataFilters !== null) {
            this.dataFilters.order_by = `${this.sort[sortedColumn.colId]} ${sortedColumn.sort}`;
          } else {
            this.dataFilters = JSON.parse(JSON.stringify(this.objectFilters));
            this.dataFilters.order_by = `${this.sort[sortedColumn.colId]} ${sortedColumn.sort}`;
          }
          this.maxLimit = 119;
          this.$emit("loadMoreItemsByFilters", this.dataFilters);
        }
      }
    },
    onSelectionChanged() {
      if (this.gridApi.getSelectedRows()[0] !== undefined) {
        this.rowSelected(this.gridApi.getSelectedRows()[0]);
      }
    },
    rowSelected(data) {
      const loadStatusType = this.setLoadStatusColor(data.load_status);
      let routeName = "";
      if (loadStatusType.label === "Quoted") {
        routeName = "CarriersInfo";
      } else {
        routeName = "LoadsDetails";
      }
      this.$router.push({
        name: routeName,
        params: { loadId: data.id },
      });
      this.$store.commit("load/setSearchText", "");
    },
    onBodyScrollEndEvent(event) {
      if (event.top >= this.maxLimit) {
        if (!this.loadingItems) {
          this.maxLimit += 900;
          this.$emit("loadMoreItems", this.dataFilters);
        }
      }
    },
    resetFilters(data) {
      this.$emit("loadMoreItems", data);
    },
    applyFilters(data) {
      this.dataFilters = data;
      this.notFound = false;
      this.maxLimit = 119;
      if (data === null) {
        this.$emit("loadMoreItems", data);
      } else {
        this.$emit("loadMoreItemsByFilters", data);
      }
      const storageMyLoadTable = {
        filters: this.dataFilters,
        columns: this.columnsSelected,
      };
      if (!this.filtersFromDashboard) {
        localStorage.setItem("my-load-table", JSON.stringify(storageMyLoadTable));
      }
      this.filtersSelectedStorage = this.dataFilters;
      this.clearSortby();
    },
    exportData() {
      this.loadingExport = true;
      this.activeColumns = false;
      this.activeFilters = false;
      this.activeExport = true;
      if (this.dataFilters !== null) {
        this.loadMoreItemsWhitFiltersAvances(this.dataFilters);
      } else {
        this.loadMoreItemsWhitFiltersAvances(this.objectFilters);
      }
    },
    exportExcel(data) {
      const createXLSLFormatObj = [];
      const newXlsHeader = [];
      const vm = this;

      vm.columnsExport.map((column) => newXlsHeader.push(column.label));
      createXLSLFormatObj.push(newXlsHeader);
      data.map((value) => {
        const innerRowData = [];
        vm.columnsExport.map((val) => {
          let fieldValue = value[val.field];
          if (val.field.split(".").length > 1) {
            fieldValue = this.getNestedValue(value, val.field);
          }
          if (val.dataFormat && typeof val.dataFormat === "function") {
            return innerRowData.push(val.dataFormat(fieldValue));
          }
          return innerRowData.push(fieldValue);
        });
        return createXLSLFormatObj.push(innerRowData);
      });

      const fileName = "My loads.xlsx";
      const wsName = "sheetname";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, wsName);
      XLSX.writeFile(wb, fileName);
      this.loadingExport = false;
    },
    getNestedValue(object, string) {
      const string1 = string.replace(/\[(\w+)\]/g, ".$1");
      const string2 = string1.replace(/^\./, "");
      const a = string2.split(".");
      let objectCopy = object;
      a.forEach((k) => {
        if (k in objectCopy) {
          objectCopy = objectCopy[k];
        }
      });
      return object;
    },
    changeStatusFilters(value) {
      this.activeFilters = value;
      this.activeColumns = false;
    },
    changeStatusColumns(value) {
      this.activeColumns = value;
      this.activeFilters = false;
    },
    applyColumns(value) {
      this.columnsSelected = value;
      this.columnDefsSeleted = [];
      this.columnsExport = [];
      this.columnDefsSeleted.push(this.name);
      this.columnDefsSeleted.push(this.loadStatus);
      this.columnDefsSeleted.push(this.origin);
      this.columnDefsSeleted.push(this.destination);
      this.columnDefsSeleted.push(this.proNumber);
      this.columnDefsSeleted.push(this.carrier);
      this.columnDefsSeleted.push(this.accessorials);
      this.columnDefsSeleted.push(this.createdDate);
      this.columnDefsSeleted.push(this.shipDate);
      this.columnDefsSeleted.push(this.revenueCost);

      this.columnsExport.push(this.nameExport);
      this.columnsExport.push(this.loadStatusExport);
      this.columnsExport.push(this.originExport);
      this.columnsExport.push(this.destinationExport);
      this.columnsExport.push(this.proNumberExport);
      this.columnsExport.push(this.carrierExport);
      this.columnsExport.push(this.accessorialsExport);
      this.columnsExport.push(this.createdDateExport);
      this.columnsExport.push(this.shipDateDateExport);
      this.columnsExport.push(this.revenueCostExport);
      if (value.includes("actual_pickup_date")) {
        this.columnDefsSeleted.push(this.dateAPD);
        this.columnsExport.push(this.dateAPDExport);
      }
      if (value.includes("actual_delivery_date")) {
        this.columnDefsSeleted.push(this.dateADD);
        this.columnsExport.push(this.dateADDExport);
      }
      if (value.includes("expected_delivery_date")) {
        this.columnDefsSeleted.push(this.dateEDD);
        this.columnsExport.push(this.dateEDDExport);
      }
      if (value.includes("customer")) {
        this.columnDefsSeleted.push(this.customer);
        this.columnsExport.push(this.customerExport);
      }
      if (value !== "") {
        const storageMyLoadTable = {
          filters: this.dataFilters,
          columns: this.columnsSelected,
        };
        if (!this.filtersFromDashboard) {
          localStorage.setItem("my-load-table", JSON.stringify(storageMyLoadTable));
        }
      }
      this.columnsSelected = value;
      this.gridOptions.api.setColumnDefs(this.columnDefsSeleted);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.updateData(this.arrayMyLoads, params);
    },
    updateData(data, paramsRow) {
      const dataSource = {
        rowCount: undefined,
        getRows: (params) => {
          const rowsThisPage = data.slice(params.startRow, params.endRow);
          let lastRow = -1;
          if (data.length <= params.endRow) {
            lastRow = data.length;
          }
          params.successCallback(rowsThisPage, lastRow);
        },
      };
      paramsRow.api.setDatasource(dataSource);
    },
    filtersAndColumns() {
      const infoLocalStorage = JSON.parse(localStorage.getItem("my-load-table"));
      if (this.filters && infoLocalStorage !== undefined && infoLocalStorage !== null) {
        this.columnsSelectedStorage = infoLocalStorage.columns;
        this.filtersSelectedStorage = this.filters;
        this.dataFilters = this.filters;
        if (this.columnsSelectedStorage !== undefined && this.columnsSelectedStorage !== "") {
          this.applyColumns(this.columnsSelectedStorage);
        } else {
          this.applyColumns("");
        }
      } else {
        this.applyColumns("");
      }
    },
    inactiveTools() {
      this.activeFilters = false;
      this.activeColumns = false;
    },
    async loadMoreItemsWhitFiltersAvances(data) {
      this.dataExport = [];
      if (this.$options.filters.checkPermission("get:loads")) {
        const dataFilter = {
          limit: 200,
          skip: 0,
          ...(data.origin && {
            origin: data.origin,
          }),
          ...(data.destination && {
            destination: data.destination,
          }),
          ...(data.statuses && data.statuses.length !== 0 && {
            statuses: data.statuses,
          }),
          ...(data.modes && data.modes.length !== 0 && {
            modes: data.modes,
          }),
          ...(data.accessorial_id && {
            accessorial_id: data.accessorial_id,
          }),
          ...(data.carrier && {
            carrier: data.carrier,
          }),
          ...(data.created_date_gt && {
            created_date_gt: data.created_date_gt,
          }),
          ...(data.created_date_lt && {
            created_date_lt: data.created_date_lt,
          }),
          ...(data.expected_delivery_date_gt && {
            expected_delivery_date_gt: data.expected_delivery_date_gt,
          }),
          ...(data.expected_delivery_date_lt && {
            expected_delivery_date_lt: data.expected_delivery_date_lt,
          }),
          ...(data.expected_ship_date_gt && {
            expected_ship_date_gt: data.expected_ship_date_gt,
          }),
          ...(data.expected_ship_date_lt && {
            expected_ship_date_lt: data.expected_ship_date_lt,
          }),
          ...(data.actual_delivery_date_gt && {
            actual_delivery_date_gt: data.actual_delivery_date_gt,
          }),
          ...(data.actual_delivery_date_lt && {
            actual_delivery_date_lt: data.actual_delivery_date_lt,
          }),
          ...(data.actual_pickup_date_gt && {
            actual_pickup_date_gt: data.actual_pickup_date_gt,
          }),
          ...(data.actual_pickup_date_lt && {
            actual_pickup_date_lt: data.actual_pickup_date_lt,
          }),
          ...(data.included_accessorials && {
            included_accessorials: data.included_accessorials,
          }),
          ...(data.order_by && {
            order_by: data.order_by,
          }),
        };
        const response = await this.$store.dispatch("load/getLoadsAdvanced", dataFilter);
        if (response.length === 200) {
          this.swal({
            title: "Info",
            text: this.$t("myLoads.exportData"),
            icon: "warning",
          });
          this.exportExcel(response);
        } else {
          this.exportExcel(response);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/components/Checkboxes.scss";
@import "../../assets/scss/components/Dropdown.scss";

.my-loads {
  width: 100%;
  margin-top: 10px;
  &__tools {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
  }
  &__table {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
  }
  &__loading {
    position: relative;
    top: 50%;
  }
  &__not-found {
    margin-top: -450px;
    &__icon {
      font-size: 50px;
    }
  }
}
.export-button {
  border-radius: 5px;
  border: 2px solid var(--color-border-container);
  color: var(--color-primary-company);
  background-color: #efefef;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    background: var(--color-border-container);
    color: #efefef;
  }
  &:active {
    background: var(--color-border-container);
    color: #efefef;
  }
}
.loading {
  @include loading-overlay;
  background-color: transparent;
  svg,
  p {
    color: $color-primary-company;
  }
}
.cell-wrap-text {
  white-space: normal !important;
}
.expired-date {
  color: red;
}
//CSS for the per page buttons
::v-deep .btn-group,
.btn-group-vertical {
  margin-left: 1px;
}
::v-deep .btn-group .btn {
  border: 2px solid $color-border-container;
  color: $color-primary-company;
  background-color: $color-gray-light;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled).active {
  border: 2px solid $color-border-container;
  border-radius: 5px;
  color: white;
  background-color: $color-border-container;
}
::v-deep .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 5px;
  margin-right: 5px;
}
::v-deep .btn-group > .btn:last-child:not(.dropdown-toggle) {
  border-radius: 5px;
}
::v-deep .btn {
  padding: 0.2rem 0.4rem;
  font-weight: 600;
  @include font-small-button-text;
}
::v-deep .spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.2rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
</style>
